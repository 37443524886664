h1, h2, h3, h4, h5, h6 {
  /** font **/
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 600;
}

h1 {
  font-size: 28px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 22px;
}

h4 {
  font-size: 20px;
}

p {
  color: black;
  font-size: 16px;
}
