/* Core CSS required for Ionic components to work properly */
@import '~@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/react/css/normalize.css';
@import '~@ionic/react/css/structure.css';
@import '~@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/react/css/padding.css';
@import '~@ionic/react/css/float-elements.css';
@import '~@ionic/react/css/text-alignment.css';
@import '~@ionic/react/css/text-transformation.css';
@import '~@ionic/react/css/flex-utils.css';
@import '~@ionic/react/css/display.css';

/* Theme variables */
@import './style/fonts.scss';
@import './style/variables.scss';
@import './style/general.scss';

.testApp {
  ion-toolbar {

    --background:   /* On "top" */
        repeating-linear-gradient(
                45deg,
                var(--ion-color-secondary-tint),
                var(--ion-color-secondary-tint) 10px,
                #fff 10px,
                #fff 20px
        ),
          /* on "bottom" */
        linear-gradient(
                to bottom,
                #fff,
                var(--ion-color-secondary-tint)
        );
  }
}

.assembly-alert {
  --max-width: 450px;
}
