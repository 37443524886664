.color-indicator {
  background: var(--ion-color-light);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  display: inline-flex;

  // Progress
  &--502 {
    background: var(--ion-color-medium);
  }

  // Loaded
  &--504 {
    background: var(--ion-color-primary);
  }

  // Checked
  &--505 {
    background: var(--ion-color-dark);
  }

  //Issue
  &--512 {
    background: var(--ion-color-danger);
  }
}
