@font-face {
  font-family: 'Barlow Condensed';
  src: url('../fonts/BarlowCondensed-Thin.ttf');
  font-weight: 100;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('../fonts/BarlowCondensed-ThinItalic.ttf');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('../fonts/BarlowCondensed-ExtraLight.ttf');
  font-weight: 200;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('../fonts/BarlowCondensed-ExtraLightItalic.ttf');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('../fonts/BarlowCondensed-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('../fonts/BarlowCondensed-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('../fonts/BarlowCondensed-Regular.ttf');
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('../fonts/BarlowCondensed-Italic.ttf');
  font-style: italic;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('../fonts/BarlowCondensed-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('../fonts/BarlowCondensed-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('../fonts/BarlowCondensed-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('../fonts/BarlowCondensed-SemiBoldItalic.ttf');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('../fonts/BarlowCondensed-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('../fonts/BarlowCondensed-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('../fonts/BarlowCondensed-ExtraBold.ttf');
  font-weight: 800;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('../fonts/BarlowCondensed-ExtraBoldItalic.ttf');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('../fonts/BarlowCondensed-Black.ttf');
  font-weight: 900;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('../fonts/BarlowCondensed-BlackItalic.ttf');
  font-weight: 900;
  font-style: italic;
}
